.notice-wrap {
  position: absolute;
  top: $headerHeight;
  z-index: 100;
  left: calc(50% + 130px);
  width: 220px;
  touch-action: none;
  cursor: pointer;
  user-select: none;

  &.budget-notice-wrap {
    left: calc(50% + 400px);
  }

  &:hover {
    .notice-text {
      // box-shadow: 0 5px 10px 0 rgba(0,0,0,.25);
    }
  }
}

.notice-action {
  width: 100%;
  display: flex;
  justify-content: center;
  color: $gray;
  transform: rotate(-2deg);
  padding: 0;
}

.notice-text {
  padding: 6px 10px 10px 10px;
  font-size: 12px;
  color: $dark;
  white-space: pre-line;
  cursor: pointer;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08);
  background: #fdf758;
  transform: rotate(-2deg);

  max-height: 80px;
  overflow: hidden;

  &.show-more {
    max-height: none;
  }

  .budget-notice-wrap & {
    background: $alert;
    color: $white;
  }
}

.notice-header {
  width: 100%;
  height: 30px;
  padding: 5px;
  padding-left: 30px;
}

i.notice-icon {
  position: absolute;
  top: -23px;
  right: -17px;
  font-size: 22px;
  z-index: 10;
  padding: 10px;
}
