.p-tag {
  white-space: nowrap;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 11px;
  padding: 3px 10px;
  background: $blue;
  user-select: none;
  font-style: normal;

  .pi {
    margin: 0 8px 0 0;
    font-size: 12px;
  }
}

.p-tag.p-tag-outline {
  background: transparent;
  border: 1px solid $lightHighlight;
  color: $lightDark;
}

.p-tag.p-tag-success {
  background: $main2;
}

a.p-tag-success {
  &:hover {
    color: $white;
  }
}

.p-tag.p-tag-OFFER_OPEN {
  background: $main2;
}

.p-tag.p-tag-FINISHED,
.p-tag.p-tag-EMPLOYMENT {
  background: $main2;
}

.p-tag.p-tag-danger {
  background: $alert;
}

.p-tag.p-tag-PAUSED {
  background: $orange;
}

.p-tag.p-tag-EDITED {
  background: $orange;
}

.p-tag.p-tag-EDIT {
  background: $orange;
}

.p-tag.p-tag-QUALI_OPEN {
  background: $orange;
}

.p-tag.p-tag-STORNO,
.p-tag.p-tag-CANCELED {
  background: $alert;
}

.p-tag.p-tag-ILL,
.p-tag.p-tag-INVOICE_STORNO,
.p-tag.p-tag-DELETED {
  background: $alert;
}

.p-tag.p-tag-KI {
  background: $lightDark;
}

.p-tag.p-tag-NEW,
.p-tag.p-tag-HOSPITATION {
  background: $blue;
}

.p-tag.p-tag-VACATION {
  background: $blue;
}

.p-tag.p-tag-FREE_TIME_REQUEST {
  background: $main1;
}

.p-tag.p-tag-JOB_INTERVIEW {
  background: $main1;
}

.p-tag.p-tag-OPEN {
  background: $blue;
}

.p-tag.p-tag-DLV_OPEN {
  background: $blue;
}

.p-tag.p-tag-GRAY {
  background: $gray;
}

.p-tag.p-tag-OTHER {
  background: $main2;
}

.p-tag.p-tag-TRAINING {
  background: $main2;
}

.p-tag.p-tag-INTERN {
  background: $gray;
}

.p-tag.p-tag-QUALIFICATION_OPEN,
.p-tag.p-tag-INVOICED,
.p-tag-PLANNING_FOLLOWS,
.p-tag.p-tag-HOSPITATION_STARTED,
.p-tag.p-tag-APPLICANT {
  background: $paused;
}

.p-tag-clickable {
  cursor: pointer;

  &:active {
    opacity: 0.8;
  }
}

.p-tag.p-tag-REGULAR,
.p-tag.p-tag-INVOICE_FINISHED {
  background: $main2;
}

.header-buttons {
  .p-badge {
    font-size: 11px;
    min-width: 15px;
    height: 15px;
    line-height: 15px;
    font-weight: 600;
    background: $dark;
  }
}

nav,
h2 {
  .p-badge {
    color: $lightGray;
    font-size: 12px;
    min-width: 17px;
    height: 17px;
    line-height: 17px;
    font-weight: 600;
    background: $gray;
    margin: 0 0 0 5px;
  }
}

.p-badge.p-badge-info {
  background: $blue;
}

.alert-badge {
  .p-badge {
    background: $alert;
    animation: blink 1s infinite;

    .active & {
      animation: none;
    }
  }
}
