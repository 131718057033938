.p-dialog-mask.p-component-overlay {
  background: rgba($dark, 0.5);
}

.p-dialog-mask {
  // pointer-events: auto !important;
}

.current-patient-highlight {
  font-weight: 800;
}

.intern-message-container {
  white-space: pre-wrap;

  img {
    padding: 20px;
    background: $hoverLight;
    max-width: 100%;
  }
}

.dialog-progress-bar-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 100%;

  .p-progressbar {
    border-radius: 0 !important;
  }

  .p-progressbar-label {
    display: none !important;
  }

  .p-progressbar-value {
    background: $gradient !important;
  }

  .p-progressbar {
    background: $background !important;
  }
}

.dialog-close-icon-fake {
  position: absolute;
  top: 0;
  right: 0;
  padding: 13px 15px;
  border: none;
  background: transparent;
  color: $white;
  cursor: pointer;
  display: flex;

  &:active {
    opacity: 0.6;
  }
}

.p-dialog .p-dialog-header {
  background: $gradient;
  color: $white;
  padding: 5px 10px 5px 20px;
  border-radius: 0 !important;

  .p-dialog-title {
    font-size: 16px;
    font-weight: normal;
  }
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  color: $white;
  box-shadow: none !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: $white;
  background: rgba($white, 0.3);
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:active {
  background: transparent;
}

.dialog-with-own-close {
  &.p-dialog .p-dialog-header {
    padding: 10px 10px 10px 20px !important;
  }
}

.dialog-diff-view {
  width: 100%;
  max-width: 1000px;

  .p-dialog-content {
    height: 600px;
    //min-height: 300px;
  }

  .diff-line {
    display: flex;
    padding: 10px;
    color: $dark;
    font-size: 14px;

    &:hover {
      background: $hoverLight;
    }

    &.changed {
      background: rgba($alert, 0.2);
    }

    div {
      width: 50%;
      white-space: pre-line;

      &:first-child {
        padding: 0 20px 0 0;
      }
    }
  }

  .diff-line-full {
    div {
      width: 100%;
    }
  }

  .diff-header {
    display: flex;
    padding: 20px 10px 10px 10px;
    position: sticky;
    top: 0;
    background: $white;

    div {
      width: 50%;
      font-size: 14px;
      font-weight: bold;
      color: $gray;
      text-transform: uppercase;
    }
  }
}

.dialog-with-sidebar-secondary {
  // TODO: Die px in eine Variable schieben, je nachdem wie groß die sidebar ist.
  margin: 0 0 0 320px;
}

.dialog-container {
  textarea,
  .p-multiselect,
  p-dropdown,
  .p-dropdown,
  .p-autocomplete,
  .p-calendar {
    width: 100% !important;
  }

  p-dropdown {
    overflow: hidden !important;
  }

  .p-dialog-content {
    padding: 0 !important;
  }

  .dialog-form {
    .p-formgroup-inline {
      display: flex;
      // justify-content: space-between;
    }
  }
}

.system-message-assignments {
  display: flex;
  border-bottom: 1px solid rgba($white, 0.4);
  padding: 5px 0;
  justify-content: space-between;
  align-items: center;

  > div {
    display: flex;
    flex-direction: column;
  }

  .pi {
    color: $white;
  }
}

.system-message-name {
  color: $white;
  font-size: 15px;
}

.system-message-value {
  color: rgba($white, 0.9);
  font-size: 14px;
}

.dialog-sidebar-has-table {
  table {
    width: 100%;
    font-size: 12px;
    text-align: left;
  }

  th,
  td {
    width: 16.66%;
  }
}

.google-maps-sidebar {
  overflow: hidden !important;
}

.dialog-sidebar {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 42px; // Dialog Header
  left: 100%;
  width: 380px;
  background: $blue;
  padding: 20px;
  overflow-y: auto;
  height: calc(100% - 98px); // Dialog Header und Dialog Footer

  &.white-sidebar {
    border-left: 1px solid $background;
    background: $white;

    label,
    small {
      color: $dark;
    }

    h4 {
      color: $dark;
    }
  }

  h4 {
    color: $white;
    font-size: 15px;
    height: 40px;
  }

  &.without-padding {
    padding: 0;
  }

  &.space-between {
    justify-content: space-between;
  }

  label,
  small {
    color: $white;
  }

  &.smaller-dialog-sidebar {
    width: 320px;
  }

  &.bigger-dialog-sidebar {
    width: 540px;
  }

  &.middle-dialog-sidebar {
    width: 450px;
  }

  &.secondary-dialog-sidebar {
    right: 100%;
    left: auto;
    background: $white;
    border-right: 1px solid $background;

    label,
    small {
      color: $dark;
    }
  }
}

.dialog-phone-numbers {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $background;

  &:hover {
    &.clickable {
      background: $hoverLight;
    }
  }

  &:active {
    &.clickable {
      background: $background;
    }
  }

  &.clickable {
    cursor: pointer;
  }

  .pi-info-circle {
    margin: 0 0 0 10px;
    font-size: 12px;
    color: $gray;
  }

  a {
    color: $gray;

    &:hover {
      text-decoration: underline;
    }

    &:active {
      text-decoration: none;
    }
  }

  &:last-child {
    border: none;
  }
}

.proof-shipping-items {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.proof-shipping-item {
  display: flex;
  border: 1px solid $background;
  padding: 10px;
  gap: 20px;
  flex-direction: column;

  &.highlight {
    background: rgba($blue, 0.07) !important;
  }

  .p-field-checkbox {
    margin-bottom: 0 !important;
  }

  .p-field {
    margin: 0 !important;

    > label {
      font-size: 15px;
      margin: 0 0 15px 0;
      gap: 5px;
      display: flex;
      align-items: center;
    }
  }

  > div {
    width: 100%;
    //display: flex;
    //flex-direction: column;
  }
}

.sidebar-inline-calendar {
  display: flex;
  width: 100%;

  p-calendar {
    display: flex;
    width: 100%;
  }

  p-table {
    width: 100%;
  }

  .p-datepicker {
    border: none !important;
  }

  &.with-border {
    border-bottom: 1px solid $background;
  }

  &.part-payment-container {
    background: $white;
    border-left: 1px solid $background;
    //background: $hoverLight;
    flex-direction: column;
    padding: 20px;
    color: $dark;
    width: 400px;
  }
}

.part-payment-container-inner {
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  &.with-border {
    border-top: 1px solid $background;
  }

  .dialog-sidebar-part-payment {
    border-bottom: 1px solid $background;

    &:last-child {
      border: none;
    }

    strong {
      font-size: 14px;
      color: $dark;
      white-space: pre-wrap;
    }

    span {
      margin: 5px 0 0 0;
      color: $gray;
      font-size: 14px;
    }

    .pi-pencil {
      color: $gray;
    }
  }
}

.step-badge {
  border-radius: 100%;
  width: 20px;
  height: 20px;
  background: $background;
  color: $gray;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-size: 14px;
}

.dialog-sidebar-tabs {
  display: flex;
  height: 50px;
  border-bottom: 1px solid $background;
  padding: 0 20px;
  gap: 10px;
  flex-shrink: 0;
  width: 100%;
  align-items: center;

  .pi-arrow-right {
    color: $lightHighlight;
  }

  .p-badge {
    font-size: 11px;
    min-width: 15px;
    height: 15px;
    line-height: 15px;
    font-weight: 600;
    background: $blue;
  }

  > span {
    color: $dark;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    height: 100%;
    border-bottom: 3px solid transparent;
    cursor: pointer;
    gap: 10px;
    padding: 0 10px;

    &:hover {
      border-bottom: 3px solid $lightGray;
    }

    &:active {
      border-bottom: 3px solid $gray;
    }

    &.active {
      border-bottom: 3px solid $main1;
      color: $main1;
    }

    &.disabled {
      color: $gray;
      pointer-events: none;
    }
  }
}

.drive-time-info-in-list {
  display: flex;
  align-items: center;
  gap: 6px;
  position: relative;
  color: $gray !important;
  font-size: 12px;
}

.drive-time-line {
  position: absolute;
  width: 2px;
  background: $bg;
  height: 20px;
}

.dialog-sidebar-information {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  gap: 5px;
  margin: 0 0 20px 0;

  > div {
    display: flex;
  }
}

.dialog-sidebar-change-information {
  display: flex;
  border-top: 1px solid $background;
  font-size: 15px;
  padding: 20px 0 20px 0;
  justify-content: space-between;

  > div {
    display: flex;
    flex-direction: column;
  }
}

.dialog-sidebar-accordion-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.dialog-sidebar-accordion {
  display: flex;
  flex-direction: column;
}

.dialog-sidebar-accordion-header {
  display: flex;
  gap: 10px;
  padding: 5px 15px;
  border: 1px solid $lightHighlight;
  width: 100%;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;

  .pi-chevron-down,
  .pi-chevron-up {
    margin-left: auto;
  }

  &:hover {
    background: $background;
  }

  &:active {
    background: transparent;
  }
}

.dialog-sidebar-accordion-content {
  display: flex;
  flex-direction: column;
  border-right: 1px solid $lightHighlight;
  border-bottom: 1px solid $lightHighlight;
  border-left: 1px solid $lightHighlight;

  .dialog-no-message {
    text-align: center;
  }
}

.inline-dropdown {
  font-size: 14px;
  display: flex;
  padding: 1px 0 0 0;
}

.dialog-sidebar-accordion-list {
  display: flex;
  flex-direction: column;
  padding: 5px 15px;
  border-bottom: 1px solid $background;
  cursor: pointer;

  &:hover {
    background: $background;
  }

  &:active {
    background: transparent;
  }

  &.not-clickable {
    cursor: default;
  }

  > span {
    font-size: 15px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > small {
    font-size: 13px !important;
    color: $gray !important;
  }

  &:last-child {
    border: none;
  }
}

.dialog-sidebar-comments-container {
  display: flex;
  flex-direction: column;
  border-top: 1px solid $background;
}

.dialog-sidebar-part-payments-container {
  display: flex;
  flex-direction: column;

  &.with-border {
    border-top: 1px solid rgba($white, 0.4);
  }

  .dialog-no-message {
    padding: 20px 0;
    color: $white;
  }
}

.dialog-sidebar-comment {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $background;
  padding: 10px 0;
  position: relative;

  &.selectable {
    cursor: pointer;

    &:hover {
      background: $hoverLight;
    }

    &:active {
      background: transparent;
    }
  }

  &.highlighted {
    background: $hoverLight;
  }

  &:last-child {
    border: none;
  }

  strong {
    font-size: 13px;
    color: $dark;
    white-space: pre-wrap;
  }

  span {
    margin: 5px 0 0 0;
    color: $gray;
    font-size: 13px;
  }

  &:hover {
    .pi-pencil {
      display: block;
    }
  }

  .pi-pencil {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    padding: 15px;
    display: none;

    &:active {
      opacity: 0.6;
    }
  }
}

.dialog-sidebar-part-payment {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba($white, 0.4);
  padding: 10px 0;
  position: relative;

  &.selectable {
    cursor: pointer;

    &:hover {
      background: $hoverLight;
    }

    &:active {
      background: transparent;
    }
  }

  &:last-child {
    border: none;
  }

  strong {
    font-size: 14px;
    color: $white;
    white-space: pre-wrap;
  }

  span {
    margin: 5px 0 0 0;
    color: rgba($white, 0.8);
    font-size: 14px;
  }

  &:hover {
    .pi-pencil {
      display: block;
    }
  }

  .pi-pencil {
    color: $white;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    padding: 15px;
    display: none;

    &:active {
      opacity: 0.6;
    }
  }
}

.jodit-full-height {
  .jodit-container,
  &.p-field {
    height: 100% !important;
  }
}

.dialog-left-side {
  border-right: 1px solid $background;
  width: 35%;
  padding: 20px;
}

.dialog-right-side {
  width: 65%;
  padding: 20px;
}

.dialog-form-body-banner {
  display: flex;
  background: $orange;
  color: $white;
  padding: 3px 20px;

  &.phone-highlight-banner {
    justify-content: center;
    background: $blue;
  }
}

.dialog-form-body {
  padding: 20px;

  &.border-top {
    border-top: 1px solid $background;
  }

  &.without-padding {
    padding: 0;
  }

  &.highlight {
    background: $hoverLight;
  }
}

.dialog-reasons-container {
  background: $hoverLight;
  padding: 10px 20px 0 20px;

  label b {
    font-size: 13px;
  }

  .p-field {
    margin: 0;
  }

  label {
    margin: 0;
  }

  .p-formgrid {
    padding: 5px 0 0 0 !important;
  }
}

.remove-position-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .pi {
    cursor: pointer;
    padding: 5px 10px;

    &:active {
      opacity: 0.6;
    }
  }
}

.add-position-container {
  align-items: center;
  display: flex;

  .pi {
    cursor: pointer !important;
    padding: 10px 0;

    &:active {
      opacity: 0.6;
    }
  }
}

.dialog-footer-action-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.dialog-form-footer {
  border-top: 1px solid $background;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  min-height: $dialogFooterHeight;

  width: 100%;
  justify-content: space-between;

  &.on-top {
    border-top: none;
    border-bottom: 1px solid $background;
  }

  .pi {
    cursor: pointer;

    &:active {
      opacity: 0.6;
    }
  }
}

.dialog-form-footer-textarea {
  flex-direction: column;
}

.dialog-form-footer-actions {
  display: flex;
  margin: 10px 0 0 0;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.dialog-list-container {
  display: flex;
  flex-direction: column;
  font-size: 14px;

  a {
    font-size: 13px;
    color: $blue;
    text-decoration: none;
    margin: 0 0 0 10px;

    &:hover {
      text-decoration: underline;
    }

    &:active {
      color: $gray;
    }
  }

  span {
    margin: 0 0 5px 0;

    &:last-child {
      margin: 0;
    }
  }
}

.highlight-label {
  text-transform: uppercase;
}

.dialog-no-message {
  font-size: 15px;
  color: rgba($white, 0.8);

  .white-sidebar & {
    color: $gray;
  }

  .secondary-dialog-sidebar & {
    color: $gray;
    padding: 10px 0;
  }
}

.document-list {
  display: flex;
  justify-content: space-between;
  color: #fff;
  font-size: 14px;
  padding: 3px 0;
  align-items: center;

  .white-sidebar & {
    color: $gray;

    a {
      color: $gray;
    }
  }

  .dialog-form-body & {
    color: $dark;
    flex-direction: column;
    align-items: flex-start;

    a {
      color: $gray;
    }
  }

  &:hover {
    background: rgba($white, 0.2);
  }

  a {
    color: $lightGray;
    text-decoration: none;
  }

  .pi {
    opacity: 0.7;
    cursor: pointer;
    font-size: 12px;
    padding: 2px 10px;

    &:hover {
      opacity: 1;
    }

    &:active {
      opacity: 0.7;
    }
  }
}

.dialog-text-link {
  font-size: 14px;
  color: rgba($white, 0.8);

  span {
    color: #fff;
    cursor: pointer;
    border-bottom: 1px solid rgba($white, 0.8);

    &:active {
      opacity: 0.8;
    }
  }
}

.dialog-detail-information-body {
  display: flex;
  flex-direction: column;
}

.dialog-detail-information {
  display: flex;
  width: 100%;
  padding: 5px 20px;
  border-bottom: 1px solid $background;

  &.without-padding {
    padding: 0;
  }

  &:hover {
    background: $hoverLight;
  }

  &:last-child {
    margin: 0;
    border: none;
  }
}

.dialog-detail-key {
  display: flex;
  width: 40%;

  span {
    font-size: 13px;
    color: $lightDark;
    margin: 0 0 2px 0;
    word-break: break-word;
    cursor: default;
  }
}

.dialog-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 180px;
  background: rgba($white, 0.9);
  z-index: 100;
  flex-direction: column;
  font-size: 15px;
  font-weight: bold;
  color: $dark;

  &.white-background {
    background: $white;
  }
}

.dialog-detail-actions {
  display: flex;
  justify-content: space-between;
  //flex-direction: column;
  // align-items: flex-start;

  button {
    // margin: 20px 0 0 0;
  }
}

.dialog-detail-inline-edit {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dialog-detail-value {
  display: flex;
  width: 60%;

  .dialog-detail-information-has-actions & {
    justify-content: space-between;
    align-items: center;

    .pi-pencil {
      cursor: pointer;
      padding: 0 10px;

      &:active {
        opacity: 0.6;
      }
    }
  }

  > div {
    font-size: 13px;
    color: $dark;
    word-break: break-word;
  }

  strong {
    font-size: 13px;
    color: $dark;
    word-break: break-word;
  }

  a {
    font-size: 13px;
    color: $main1;
    word-break: break-word;
  }
}

.dialog-comments {
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow-y: auto;
}

.dialog-comment {
  background: $hoverLight;
  padding: 10px 30px 10px 20px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $lightGray;
  position: relative;

  &:hover {
    .pi-pencil {
      display: block;
    }
  }

  .pi-pencil {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    padding: 10px 15px;
    display: none;

    &:active {
      opacity: 0.6;
    }
  }

  .pi-info-circle {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 10px 15px;
    color: $gray;
  }

  &:last-child {
    border: none;
  }

  > div {
    color: $dark;
    white-space: pre-wrap;
  }

  strong {
    color: $dark;
    white-space: pre-wrap;
  }

  span {
    margin: 5px 0 0 0;
    color: $gray;
    font-size: 13px;
  }
}

.dialog-no-comment {
  text-align: center;
  color: $dark;
}

.invoice-dialog-sum-container {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  font-size: 15px;

  > div {
    display: flex;
  }

  span {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 160px;
    text-align: right;
    justify-content: flex-end;
  }
}

.total-sum-container {
  border-top: 2px solid $background;
  padding: 10px 0 0 0;
  margin: 10px 0 0 0;
  font-weight: 600;
}

.proof-check-container {
  display: flex;
  width: 100%;
  height: 100%;
}

.proof-check-image-container {
  display: flex;
  width: 50%;
  background: $dark;
  position: relative;

  z-index: 10;
  margin-bottom: -$dialogFooterHeight;
  margin-top: -$dialogFooterHeight;
}

.proof-check-buttons {
  position: absolute;
  top: 40px;
  left: -10px;
  padding: 0 30px;
  z-index: 10;
  justify-content: space-between;
  display: flex;
  width: 100%;

  > div {
    display: flex;
    gap: 10px;
    align-items: flex-start;
  }

  .with-multiple-buttons {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
  }
}

.proof-check-dropdown-buttons {
  display: flex;
  gap: 5px;

  .p-button-label {
    //font-size: 12px;
  }
}

.proof-check-stamp-container {
  overflow: scroll;
  width: 100%;
  height: 100%;
  position: relative;

  .draggable,
  .draggable-date {
    touch-action: none;
    user-select: none;
    background: rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 10;
  }
}

#image-lnw-for-stamp {
  top: 0;
  left: 0;
  z-index: -1;
  position: absolute;
  width: 100%;
}

.stamp-container {
  //position: absolute;
}

.proof-check-no-image-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $gray;
  font-size: 20px;
  flex-direction: column;
  gap: 10px;

  .pi {
    font-size: 92px;
    color: #3b3e46;
  }
}

.proof-check-image-item {
  display: flex;
  width: 100%;
  //height: 100%;

  //height: calc(100vh - 167px);

  &.is-rotating {
    .viewer-rotate-left {
      pointer-events: none;
      opacity: 0.6;
    }
  }
}

.proof-check-invoice-wrapper {
  width: 100%;
  //display: flex;
  padding: 100px 30px 30px 30px;
  //justify-content: center;
  overflow: auto;

  embed {
    display: flex;
  }
}

.dialog-invoice-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;

  p {
    font-size: 9px;
  }
}

.dialog-invoice-box {
  display: flex;
  flex-direction: column;
  margin: 30px 0 0 0;
  border: 1px solid $gray;
  padding: 10px;

  > div {
    display: flex;
    font-size: 15px;
    margin: 5px 0 0 0;

    &:first-child {
      margin: 0;
    }

    span {
      display: flex;
      width: 100%;
    }
  }
}

.dialog-invoice-footer {
  display: flex;
  width: 100%;
  border-top: 1px solid $gray;
  padding: 10px 0 0 0;

  div {
    display: flex;
    flex: 1;
    //width: 100%;
    font-size: 8px;

    &:first-child {
      flex: 2;
    }
  }
}

.dialog-invoice-address {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 180px;

  span {
    font-size: 9px;
    border-bottom: 1px solid $gray;
  }

  p {
    margin: 10px 0 0 0;
    font-size: 13px;
  }
}

.dialog-invoice-headline {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 14px;

  u {
    font-weight: 600;
  }
}

.dialog-invoice-sub-headline {
  display: flex;
  width: 100%;
  border: 1px solid $gray;
  margin: 10px 0 0 0;

  > div {
    display: flex;
    padding: 4px;
    border-right: 1px solid $gray;
    width: 24%;
    font-size: 11px;
    flex-direction: column;

    &:last-child {
      border: none;
      width: 28%;
    }
  }
}

.dialog-invoice-text-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 30px 0;
  min-height: 400px;

  p {
    font-size: 14px;
  }

  table {
    text-align: left;
    margin: 30px 0 0 0;
    font-size: 14px;
    border-collapse: collapse;
    border: 1px solid $gray;
  }

  thead {
    //background: $bg;

    th {
      border: 1px solid $gray;
      padding: 2px 5px;
    }
  }

  tbody td {
    padding: 2px 5px;
    border: 1px solid $gray;
  }
}

.proof-check-invoice {
  display: flex;
  background: $white;
  width: 100%;
  padding: 40px 40px;
  flex-direction: column;
}

.dialog-invoice-next-page {
  padding: 40px 40px;
  background: $white;
  margin: 20px 0 0 0;

  &.first-page {
    margin: 0 0 20px 0;
  }
}

.proof-check-actions-container {
  display: flex;
  width: 50%;
  flex-direction: column;
  border-left: 1px solid $background;
  overflow: auto;

  &.row-view {
    flex-direction: row;
  }
}

.viewer-toolbar > ul > li {
  border-radius: 0 !important;
  background: $main1 !important;
  box-shadow: none !important;

  &:hover {
    background: $main2 !important;
  }

  &:active {
    background: $main1 !important;
  }
}

.viewer-toolbar > ul > li + li {
  margin-left: 5px !important;
}
