.fc-col-header {
  width: 100% !important;
}

.fc-col-header-cell {
  font-size: 14px;
}

.fc-col-header-cell-cushion {
  width: 100%;
}

.fc-col-header-custom {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  * {
    pointer-events: none;
  }

  &:active {
    opacity: .6;
  }

  .pi {
    margin-left: auto;
    font-size: 14px;
    color: $gray;
  }
}

.fc-timegrid-slot-label {
  font-size: 14px;
}

.content-body-full-height .fc-timegrid-slots {
    table {
      height: calc(100vh - 300px) !important;
    }
}

.content-body-full-height full-calendar {
  height: 100vh !important;
}

.fc-timegrid-body {
  width: 100% !important;

  table {
    width: 100% !important;
  }

  .fc-event-time {
    display: none !important;
  }
}

.fc-event-title {
  white-space: pre-wrap;
}

.fc-timegrid-event,
.fc-timegrid-more-link {
  font-size: 13px !important;
  border-radius: 0 !important;
}
